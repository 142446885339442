/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu .ant-card-head {
  background-size: cover;
  padding: 1.5em;
}
.menu .ant-card-head .ant-card-head-title {
  color: white;
  font-size: 1.5em;
}
.menu__list-item {
  cursor: pointer;
}
.menu__price {
  font-weight: 700;
  color: #db0124;
}
.menu__spicy1 {
  color: #fa8c16 !important;
}
.menu__spicy2 {
  color: #fa541c !important;
}
.menu__spicy3 {
  color: #f5222d !important;
}
.menu__long-to-prepare {
  color: #873800 !important;
}
.menu__vegan {
  color: #52c41a !important;
}
.menu__attribute {
  margin-right: 4px;
}
