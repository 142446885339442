.step-summary-restaurant-name {
	color: #db0322 !important;
	font-family: 'Marck Script', cursive;
	font-size: 4em;

	@media screen and (max-width: 576px) {
		font-size: 2.5em;
	}
}

.step-summary-top-row {
	margin-top: 20px;
	margin-bottom: 20px;
}

.step-summary-add-discount-code-promotion-button {
	margin-top: -15px;
	background: none !important;
	border: none !important;
	padding: 0 !important;
	/*optional*/
	font-family: arial, sans-serif !important;
	/*input has OS specific font-family*/
	color: #069 !important;
	text-decoration: underline !important;
	cursor: pointer !important;
	box-shadow: none !important;
	text-align: start !important;

	span {
		text-align: start !important;
	}
}

.step-summary-add-discount-code-promotion-icon {
	margin-right: -5px !important;
	svg {
		margin-left: 5px;
		width: 24px;
		height: 20px;
	}
}

@primary-color: #db0124;@menu-collapsed-width: 40px;