.bg-site {
  background-color: white !important;
}
.content-layout {
  background: #fff;
  min-height: calc(100vh - 64px);
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.w-fit {
  width: fit-content !important;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar-track {
  border: 0.1px solid lightgray;
  border-radius: 0.25em;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}
.fixed-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.app-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  height: 200px;
  width: 200px;
}
@media screen and (max-width: 992px) {
  .app-logo {
    position: sticky !important;
    top: calc(30vh - 64px) !important;
    height: 100px !important;
    width: 100px !important;
    margin-left: -50px;
  }
}
@media screen and (max-width: 992px) {
  .login-container-form-block {
    height: calc(70vh - 64px) !important;
  }
}
@media screen and (min-width: 992px) {
  .login-container-form-block {
    height: calc(100vh - 64px);
  }
}
.login-container-image-block {
  background-image: url('./assets/login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 992px) {
  .login-container-image-block {
    height: calc(30vh - 64px) !important;
  }
}
@media screen and (min-width: 992px) {
  .login-container-image-block {
    height: calc(100vh - 64px);
  }
}
