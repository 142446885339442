.bg-site {
  background-color: white !important;
}
.content-layout {
  background: #fff;
  min-height: calc(100vh - 64px);
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.w-fit {
  width: fit-content !important;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar-track {
  border: 0.1px solid lightgray;
  border-radius: 0.25em;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}
.fixed-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.ant-layout-header.fixed-header.bg-site {
  padding-top: 10px;
  background-color: white !important;
  height: 80px;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
  background-color: white !important;
}
.ant-menu-item a {
  font-size: 1.1em;
}
.home-container-landing-image-block {
  margin-top: -64px;
  background-image: url('./assets/landing-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 800px !important;
  height: 100vh;
}
.home-container-landing-text-block {
  color: white;
}
.home-container-landing-text-header {
  margin-top: 200px;
  font-size: 5em;
  line-height: 120%;
  font-family: 'Marck Script', cursive;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .home-container-landing-text-header {
    font-size: 4em;
    margin-top: 180px;
  }
}
.home-container-landing-text-description {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 1.6em;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .home-container-landing-text-description {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home-container-landing-divider {
  width: 150px;
  height: 5px;
  background-color: #db0322;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.home-container-landing-down-button {
  font-size: 22px !important;
  color: white !important;
  position: absolute !important;
  z-index: 100;
  bottom: 30px !important;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.home-container-landing-down-button:hover {
  transform: scale(1.1);
}
.home-container-landing-button-block {
  text-align: center;
}
.home-container-landing-button {
  font-size: 20px !important;
  height: 50px !important;
  width: 300px !important;
  margin-top: 10px;
  background-color: #db0322 !important;
  color: white !important;
  border-color: #db0322 !important;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 992px) {
  .home-container-landing-button {
    font-size: 14px !important;
    height: 40px !important;
    width: 250px !important;
  }
}
.home-container-landing-button:hover {
  transform: scale(1.1);
}
.home-container-landing-select-restaurant-dropdown {
  width: 300px;
  background-color: transparent !important;
}
@media screen and (max-width: 992px) {
  .home-container-landing-select-restaurant-dropdown {
    width: 250px !important;
  }
}
.home-container-landing-select-restaurant-dropdown div {
  background-color: transparent !important;
  border-color: white;
  color: white;
  height: 50px !important;
  font-size: 20px;
}
.home-container-landing-select-restaurant-dropdown .ant-select-arrow {
  color: white !important;
}
.home-container-landing-select-restaurant-dropdown .ant-select-selection-item {
  line-height: 50px !important;
}
.home-container-about-us-block {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 100%;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.16);
}
.home-container-about-us-leaf {
  margin-right: -50px;
  margin-top: -50px;
}
@media screen and (max-width: 992px) {
  .home-container-about-us-leaf {
    width: 10%;
    margin-right: -10px;
    margin-top: -10px;
  }
}
@media screen and (max-width: 992px) {
  .home-container-about-us-leaf {
    display: none;
  }
}
.home-container-about-us-cover-image {
  object-fit: cover;
  width: 100%;
  height: 600px;
}
@media screen and (max-width: 992px) {
  .home-container-about-us-cover-image {
    height: 200px;
  }
}
@media screen and (max-width: 768px) {
  .home-container-about-us-cover-image {
    display: none;
  }
}
.home-container-about-us-text-block {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media screen and (max-width: 576px) {
  .home-container-about-us-text-block {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.home-container-about-us-text-header {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .home-container-about-us-text-header {
    font-size: 2em;
  }
}
@media screen and (max-width: 576px) {
  .home-container-about-us-text-header {
    font-size: 1.7em;
  }
}
.home-container-about-us-text-description {
  font-size: 1.3em;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 180%;
}
@media screen and (max-width: 992px) {
  .home-container-about-us-text-description {
    font-size: 0.8em;
  }
}
.home-container-about-us-button {
  font-size: 20px !important;
  height: 50px !important;
  width: 250px !important;
  background-color: transparent !important;
  color: black !important;
  border-color: black !important;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 992px) {
  .home-container-about-us-button {
    font-size: 2em;
  }
}
@media screen and (max-width: 576px) {
  .home-container-about-us-button {
    margin-left: auto;
    margin-right: auto;
    height: 50px !important;
    width: 180px !important;
    font-size: 1em !important;
  }
}
.home-container-about-us-button:hover {
  transform: scale(1.1);
}
.home-container-menu-image-block {
  background-image: url('./assets/menu-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  color: white;
  padding-bottom: 30px;
  margin-bottom: 100px;
}
.home-container-menu-promotions {
  margin-top: 40px;
  text-decoration: underline;
  font-size: 1.3em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.home-container-menu-promotions:hover {
  transform: scale(1.1);
}
.home-container-menu-header {
  color: white;
  font-size: 3em;
  font-family: 'Marck Script', cursive;
  margin-top: 20px;
  margin-bottom: 40px;
}
@media screen and (max-width: 576px) {
  .home-container-menu-header {
    font-size: 2em;
  }
}
.home-container-menu-card-block {
  width: '100%';
  height: '300px';
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.16);
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 992px) {
  .home-container-menu-card-block {
    width: 80% !important;
    margin-bottom: 30px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 576px) {
  .home-container-menu-card-block {
    width: 50% !important;
    margin-bottom: 30px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.home-container-menu-card-button {
  margin-top: 20px;
  font-size: 16px !important;
  height: 30px !important;
  width: 140px !important;
  background-color: transparent !important;
  color: black !important;
  border-color: black !important;
  transition: all 0.2s ease-in-out;
}
.home-container-menu-card-button:hover {
  transform: scale(1.1);
}
.home-container-restaurant-info-block {
  width: 100%;
  background-color: white;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.16);
  margin-bottom: 130px;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.home-container-restaurant-info-block .home-container-restaurant-info-header {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-info-header {
    font-size: 1.7em !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-info-header {
    justify-content: center;
    margin-bottom: 10px;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-opening-hours-header {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-opening-hours-header {
    font-size: 1.7em !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-opening-hours-header {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-opening-hours {
  font-size: 1.2em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-opening-hours {
    font-size: 1em !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-opening-hours {
    justify-content: center;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-info {
  font-size: 1.2em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-info {
    font-size: 1em !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-info {
    justify-content: center;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-select-restaurant-header {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-select-restaurant-header {
    font-size: 1.7em !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-select-restaurant-header {
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-opening-hours-col {
    text-align: end;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-info-column-1 {
  padding-left: 50px;
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-info-column-1 {
    padding-left: 0px;
    justify-content: center !important;
    text-align: center;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-select-restaurant {
  width: 300px !important;
  font-size: 1.7em !important;
}
@media screen and (max-width: 1200px) {
  .home-container-restaurant-info-block .home-container-restaurant-select-restaurant {
    width: 250px !important;
    font-size: 1.3em !important;
  }
}
.home-container-restaurant-info-block .home-container-restaurant-select-restaurant div {
  border-radius: 10px !important;
}
.home-container-restaurant-info-block .home-container-restaurant-select-restaurant-button {
  margin-top: 50px;
  font-size: 1.7em !important;
  height: 50px !important;
  width: 200px !important;
  background-color: #db0322 !important;
  color: whitesmoke !important;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.2);
}
.home-container-restaurant-info-block .home-container-restaurant-select-restaurant-button:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 992px) {
  .home-container-restaurant-info-block .home-container-restaurant-select-restaurant-button {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
}
.home-container-footer-image {
  object-fit: cover;
  width: 40%;
  height: 40%;
}
.home-container-footer-block {
  background-color: black;
  padding-top: 75px;
  padding-bottom: 40px;
}
.home-container-footer-menu-block {
  margin-top: 30px;
}
@media screen and (max-width: 992px) {
  .home-container-footer-menu-block {
    text-align: center !important;
  }
}
.home-container-footer-menu-block ul {
  margin-top: 30px;
  padding: 0;
}
.home-container-footer-menu-block ul li {
  color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  margin-top: 10px;
  font-size: 14px;
}
.home-container-footer-menu-block a {
  color: white !important;
}
.home-container-footer-menu-block a:hover {
  color: red !important;
}
.home-container-footer-menu-divider {
  width: 30%;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 992px) {
  .home-container-footer-menu-divider {
    margin-left: auto;
    margin-right: auto;
  }
}
.home-container-footer-eresto-block {
  margin-top: 50px;
  font-size: 10px;
  color: whitesmoke;
}
.home-container-footer-eresto-block a {
  margin-left: 10px;
}
.home-container-footer-button {
  margin-top: 20px;
  font-size: 16px !important;
  height: 40px !important;
  width: 150px !important;
  background-color: transparent !important;
  color: whitesmoke !important;
  border-color: whitesmoke !important;
  transition: all 0.2s ease-in-out;
}
.home-container-footer-button:hover {
  transform: scale(1.1);
}
.home-container-footer-button:nth-of-type(1) {
  margin-right: 15px;
}
@media screen and (max-width: 992px) {
  .home-container-footer-button-block-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.home-container-footer-button-block-section-2 {
  color: white;
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .home-container-footer-button-block-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.home-container-footer-button-block-section-3 {
  color: white;
  margin-top: 20px;
}
.home-container-footer-button-block-section-3 a {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .home-container-footer-button-block-section-3 a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-container-footer-button-block-section-3 img {
    width: 10% !important;
  }
}
.home-container-footer-button-block-section-3 img {
  transition: all 0.2s ease-in-out;
}
.home-container-footer-button-block-section-3 img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.home-container-footer-button-block-section-3 img:nth-of-type(2) {
  margin-left: 25px;
}
.home-container-summary-row {
  background-color: rgba(0, 0, 0, 0.02);
  padding-top: 100px;
  padding-bottom: 100px;
}
.home-container-summary-block .home-container-summary-general-col {
  text-align: center;
}
@media screen and (max-width: 992px) {
  .home-container-summary-block .home-container-summary-general-col {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 992px) {
  .home-container-summary-block .home-container-summary-element-col {
    margin-bottom: 20px;
  }
}
.home-container-summary-block .home-container-summary-general {
  font-size: 3em;
  font-weight: 700;
}
@media screen and (max-width: 992px) {
  .home-container-summary-block .home-container-summary-general {
    font-size: 1.6em;
  }
}
.home-container-summary-block .home-container-summary-number {
  font-weight: bold;
  font-size: 3em;
  color: rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 992px) {
  .home-container-summary-block .home-container-summary-number {
    font-size: 1.6em;
  }
}
.home-container-summary-block .home-container-summary-header {
  font-size: 1.5em;
  line-height: 3em;
}
@media screen and (max-width: 992px) {
  .home-container-summary-block .home-container-summary-header {
    line-height: 1.6em;
  }
}
.home-container-ad-block {
  background-image: url('./assets/landing-ad.jpg');
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  border-radius: 50px !important;
  margin-top: 200px;
  margin-bottom: 200px;
}
@media screen and (max-width: 992px) {
  .home-container-ad-block {
    border-radius: 25px !important;
  }
}
.home-container-ad-block-card {
  color: white;
}
.home-container-ad-block-card .home-container-ad-block-title {
  font-size: 3em;
  width: 70%;
  text-align: center;
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  .home-container-ad-block-card .home-container-ad-block-title {
    font-size: 2em;
  }
}
.home-container-ad-block-card .home-container-ad-block-button {
  margin-top: 40px;
  font-size: 16px !important;
  height: 40px !important;
  width: 150px !important;
  background-color: transparent !important;
  color: whitesmoke !important;
  border-color: whitesmoke !important;
  transition: all 0.2s ease-in-out;
}
.home-container-ad-block-card .home-container-ad-block-button:hover {
  transform: scale(1.1);
}
.home-container-ad-block-card .home-container-ad-block-footer {
  margin-top: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.home-container-ad-block-card .home-container-ad-block-footer:hover {
  transform: scale(1.1);
}
.home-container-reviews-block .slick-dots.slick-dots-bottom li {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.home-container-reviews-block .slick-dots.slick-dots-bottom .slick-active button {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.home-container-reviews-block-header {
  font-size: 4em;
  color: #db0322;
  font-family: 'Marck Script', cursive;
}
.home-container-reviews-block-description {
  font-size: 2em;
  margin-top: 20px;
}
.home-container-reviews-block-divider {
  background-color: #db0322;
  width: 100px;
  height: 2px;
  margin-bottom: 30px;
}
.home-container-reviews-card-row {
  padding-top: 50px;
  padding-bottom: 50px;
}
.home-container-reviews-card-block {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.09);
}
@media screen and (max-width: 992px) {
  .home-container-reviews-card-block {
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.05);
  }
}
.home-container-reviews-card-block .home-container-reviews-card-review {
  color: rgba(0, 0, 0, 0.4);
}
.home-container-reviews-card-block .home-container-reviews-card-divider {
  background-color: rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.home-container-reviews-card-block .home-container-reviews-rate {
  font-size: 1em;
}
.home-container-photo-gallery-block-header {
  font-size: 4em;
  color: #db0322;
  font-family: 'Marck Script', cursive;
}
.home-container-photo-gallery-block-divider {
  background-color: #db0322;
  width: 100px;
  height: 2px;
  margin-bottom: 10px;
}
.home-container-photo-gallery-block {
  margin-top: 10px;
  margin-bottom: 100px;
}
.home-container-photo-gallery-image {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.home-container-photo-gallery-image:hover {
  transform: scale(1.15);
}
@media screen and (max-width: 992px) {
  .home-container-photo-gallery-image {
    margin: 5px;
  }
}
