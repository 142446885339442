.restaurant-list-top-row {
    margin-bottom: 100px;
}

.restaurant-list-card {
    box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.10) !important;
}

.show-menu-button {
    background-color: transparent  !important;
    border-color: #db0322 !important;
    color: #db0322 !important;
    font-weight: 400 !important;

    transition: all .2s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
    }
}

.restaurant-list-image-block {
    background-image: url('./assets/profile-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    height: 300px;

    @media screen and (max-width: 576px) {
        height: 200px;
	}
}
    


.restaurant-list-image-block-text {
	color: white;
	font-size: 5em;
	font-family: 'Marck Script', cursive;

	@media screen and (max-width: 576px) {
        font-size: 2.5em;
	}
}
@primary-color: #db0124;@menu-collapsed-width: 40px;