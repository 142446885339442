.user-icon-row {
    margin-top: 45px;
    margin-bottom: 45px;
}

.change-password-button {
    margin-right: 5px;
}

.logout-button {
    margin-left: 5px;
}

.edit-profile-container-image-block {
    background-image: url('./assets/profile-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    height: 300px;

    @media screen and (max-width: 576px) {
        height: 200px;
	}
    
}

.edit-profile-container-image-block-text {
	color: white;
	font-size: 5em;
	font-family: 'Marck Script', cursive;
    

	@media screen and (max-width: 576px) {
        font-size: 2.5em;
	}
}
@primary-color: #db0124;@menu-collapsed-width: 40px;